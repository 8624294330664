import { ButtonV2, COLORS } from "@asayinc/component-library";
import { Box, Stack, useTheme } from "@mui/material";
import { useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { isCommunicationViewed } from "./utils";
import { GradientBlock } from "src/components/atoms/GradientBlock";
import { BrokerCommunicationPageParams } from "src/types/BrokerCommunicationPageParams";
import { useCommunicationWithTrackingQuery } from "src/store/warrenG/communication";
import { useBrokerQuery } from "src/store/warrenG/broker";
import { track } from "src/analytics";
import { TrackEventType } from "src/analytics/types";
import { isProxyCommunication } from "../CommunicationDetails/utils";
import { useTrackEventQuery } from "src/store/consumer/messages/api";
import { EventHeader } from "./components/EventHeader";
import { EventDescription } from "./components/EventDescription";

export function Informational() {
  const navigate = useNavigate();
  const location = useLocation();
  const { brokerId, communicationId } =
    useParams() as BrokerCommunicationPageParams;
  const { isLoading: isCommunicationLoading, data: communication } =
    useCommunicationWithTrackingQuery({
      brokerId,
      communicationId,
    });
  const { isLoading: isBrokerLoading } = useBrokerQuery(brokerId);
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const voteSolicitationMessageId = searchParams.get("vs");

  // Track when a user comes to Communication page from a Vote Solicitation
  useTrackEventQuery(
    {
      messageId: voteSolicitationMessageId || "",
      communicationId: communicationId || "",
      type: "opened",
    },
    { skip: !voteSolicitationMessageId }
  );

  useEffect(() => {
    // We want to only show this page the first time customers view the communication.
    // If they directly try to land on this page afterwards we will redirect them to the details page.
    if (communication && isCommunicationViewed(communication)) {
      navigate("details" + location.search);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communication, navigate]);

  const isLoading = isCommunicationLoading || isBrokerLoading;

  const handleOnVoteNowClick = () => {
    track({
      name: TrackEventType.LandingPageToCommunicationDetails,
      isProxy: communication ? isProxyCommunication(communication) : undefined,
      brokerId,
      communicationId,
      customerId: communication?.customer.id,
    });
    navigate("details" + location.search);
    window.scrollTo(0, 0);
  };

  if (communication && isCommunicationViewed(communication)) {
    return null;
  }

  return (
    <Stack
      flexGrow={1}
      justifyContent="center"
      alignItems="center"
      mt={12}
      sx={{
        pt: 5,
      }}
    >
      <Stack
        sx={{
          width: "100%",
          maxWidth: "640px",
          [theme.breakpoints.down("sm")]: {
            px: 5,
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            borderTop: `6px solid ${
              isLoading ? COLORS.P_OUTLINE : theme.palette.l1.main
            }`,
            py: 8,
            px: 5,
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          }}
        >
          <EventHeader hideBanner />
          <EventDescription />
        </Box>
        <Stack
          sx={{
            position: "sticky",
            bottom: 0,
            right: 0,
          }}
        >
          <GradientBlock
            sx={{
              display: "block",
              [theme.breakpoints.down("sm")]: {
                display: "block",
              },
            }}
            height="20px"
          />
          <Stack
            sx={{
              pb: 8,
              backgroundColor: "#f0f0f0",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <ButtonV2
              sx={{
                mt: 3,
                [theme.breakpoints.down("sm")]: {
                  mt: 0,
                },
              }}
              variant="primary"
              disabled={isLoading}
              onClick={handleOnVoteNowClick}
            >
              View Documents
            </ButtonV2>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
