import { ClassActionOptOutCommunication } from "src/types/Communication";
import { formatDate } from "src/utils/formatDate";

export function getClassActionOptOutMainEventDescription(
  communication: ClassActionOptOutCommunication
) {
  const { issuerName } = communication.security;
  const { filingDate, recordDateStart, recordDateEnd } =
    communication.classActionEvent;

  const formattedFilingDate = formatDate({
    date: filingDate,
    timezone: undefined,
  });

  const formattedRecordDateStart = formatDate({
    date: recordDateStart,
    timezone: undefined,
  });

  const formattedRecordDateEnd = formatDate({
    date: recordDateEnd,
    timezone: undefined,
  });

  return `You are receiving this notification because ${issuerName} has filed for a class action lawsuit on ${formattedFilingDate} and you owned shares of ${issuerName} between ${formattedRecordDateStart} and ${formattedRecordDateEnd}. You can opt out of the class action lawsuit.`;
}

export function getClassActionOptOutSecondaryEventDescription() {
  return "The class action documents cover the details about the class action lawsuit. You can opt out of the class action lawsuit.";
}
