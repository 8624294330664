import { LoadingSkeleton, Text } from "@asayinc/component-library";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

import {
  getClassActionOptOutMainEventDescription,
  getClassActionOptOutSecondaryEventDescription,
} from "./utils";
import { BrokerCommunicationPageParams } from "src/types/BrokerCommunicationPageParams";
import { useCommunicationWithTrackingQuery } from "src/store/warrenG/communication";
import { ClassActionOptOutCommunication } from "src/types";

export function EventDescription() {
  const { brokerId, communicationId } =
    useParams() as BrokerCommunicationPageParams;
  const { isLoading, data: communication } = useCommunicationWithTrackingQuery({
    brokerId,
    communicationId,
  });

  if (isLoading || !communication) {
    return (
      <Box sx={{ mt: 8, px: 4 }}>
        <LoadingSkeleton sx={{ mb: 1 }} />
        <LoadingSkeleton sx={{ mb: 1 }} />
        <LoadingSkeleton />
      </Box>
    );
  }

  let mainEventDescription = "";
  let secondaryEventDescription = "";

  mainEventDescription = getClassActionOptOutMainEventDescription(
    communication as ClassActionOptOutCommunication
  );
  secondaryEventDescription = getClassActionOptOutSecondaryEventDescription();

  return (
    <>
      <Text
        variant="body2"
        sx={{ mt: 8, px: 4 }}
        data-testid="landing-page-main"
      >
        {mainEventDescription}
      </Text>
      <Text
        variant="body2"
        sx={{ mt: 8, px: 4 }}
        data-testid="landing-page-secondary"
      >
        {secondaryEventDescription}
      </Text>
    </>
  );
}
