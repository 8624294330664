import { ClassActionOptOutCommunication } from "src/types/Communication";

export function getClassActionEventHeader(
  communication: ClassActionOptOutCommunication
) {
  if (!communication) {
    return "";
  }

  if (communication.type == "class_action_opt_out") {
    return "Class Action";
  }

  return "";
}
