import {
  BankruptcyCommunication,
  ClassActionInformationalCommunication,
} from "src/types/Communication";

export function getEventHeader(communication: BankruptcyCommunication) {
  if (!communication) {
    return "";
  }

  if (communication.bankruptcyEvent.chapter == "generic") {
    return "Bankruptcy";
  }

  if (communication.bankruptcyEvent.chapter == "chapter_7") {
    return "Chapter 7 Bankruptcy";
  }

  if (communication.bankruptcyEvent.chapter == "chapter_11") {
    return "Chapter 11 Bankruptcy";
  }

  return "";
}

export function getClassActionEventHeader(
  communication: ClassActionInformationalCommunication
) {
  if (!communication) {
    return "";
  }

  if (communication.classActionEvent.type == "class_action_informational") {
    return "Class Action Informational Header";
  }

  return "";
}
