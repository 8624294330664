import { COLORS, Text } from "@asayinc/component-library";
import { Stack, SxProps, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";

import { Avatar } from "src/components/atoms/Avatar";
import { useCommunicationWithTrackingQuery } from "src/store/warrenG/communication";
import { BrokerCommunicationPageParams } from "src/types/BrokerCommunicationPageParams";
import { useBrokerQuery } from "src/store/warrenG/broker";
import { getClassActionEventHeader, getEventHeader } from "./utils";
import {
  BankruptcyCommunication,
  ClassActionInformationalCommunication,
} from "src/types";

interface IEventHeaderProps {
  sx?: SxProps;
  hideBanner?: boolean;
}

export function EventHeader({ sx }: IEventHeaderProps) {
  const { brokerId, communicationId } =
    useParams() as BrokerCommunicationPageParams;
  const theme = useTheme();
  const { isLoading: isCommunicationLoading, data: communication } =
    useCommunicationWithTrackingQuery({
      brokerId,
      communicationId,
    });
  const { isLoading: isBrokerLoading } = useBrokerQuery(brokerId);
  const isLoading = isCommunicationLoading || isBrokerLoading;
  let eventHeader = "";
  if (communication?.type == "bankruptcy_informational") {
    eventHeader = getEventHeader(communication as BankruptcyCommunication);
  } else if (communication?.type == "class_action_informational") {
    eventHeader = getClassActionEventHeader(
      communication as ClassActionInformationalCommunication
    );
  }

  return (
    <Stack
      data-testid="event-header"
      alignItems="center"
      sx={{ width: "100%", backgroundColor: "#FFFFFF", ...sx }}
    >
      <Avatar
        name={communication?.security.ticker}
        color={isLoading ? COLORS.P_OUTLINE : theme.palette.l1.main}
        size={48}
        sx={{ mb: 3 }}
      />
      <Text
        sx={{ mb: 3 }}
        variant="subtitle2"
        loadingProps={{ sx: { width: "120px" } }}
        data-testid="issuer-name"
      >
        {isLoading ? undefined : communication?.security.issuerName}
      </Text>
      <Text
        data-testid="communication-title"
        variant="h5"
        loadingProps={{ sx: { width: "280px" } }}
      >
        {isLoading || !communication ? undefined : eventHeader}
      </Text>
    </Stack>
  );
}
